import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Label = styled.h1`
  text-align: center;
  padding-top: 10px;
`;

const CardContainer = styled.div`
  margin: 30px auto 0;
  width: 80%;
  height: 150px;
  @media (max-width: 900px) {
    width: 100%;
    margin: 30px 0;
    height: 135px;
  }
  @media (max-width: 400px) {
    height: 160px;
  }
  @media (max-width: 370px) {
    height: 180px;
  }
`;

const Card = styled.div`
  border-left: 5px solid #f5bb0c;
  width: 100%;
  display: grid;
  grid-template-columns: 35% 65%;
  height: 100%;
  column-gap: 10px;
  justify-items: center;
  transition: transform 0.4s, box-shadow 0.4s;
  background: white;
  &:hover {
    transform: scale(1.05);
    box-shadow: 2px 12px 4px -3px rgba(0, 0, 0, 0.3);
  }
`;

const CardImgLoader = ({ src, alt }) => {
  return (
    <Fragment>
      <LazyLoadComponent>
        <CardImg src={src} effect="blur" alt={alt} />
      </LazyLoadComponent>
    </Fragment>
  );
};

const CardImg = styled.div`
  /* Has background */
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
`;

const CardTitle = styled.h2`
  align-self: center;
  color: black;
  max-width: 90%;
  @media (max-width: 900px) {
    font-size: 16px;
    letter-spacing: 1.5px;
    font-weight: 600;
  }
`;

class BlogView extends React.Component {
  render() {
    const data = this.props.data.allContentfulBlog.edges;
    let articles = data;
    return (
      <Layout>
        <SEO title="Blog" />
        <Label>{this.props.label}</Label>
        {articles.map(post => {
          return (
            <CardContainer>
              <Link
                to={`/blog/${post.node.slug}`}
                key={post.node.slug}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Card>
                  <CardImgLoader
                    src={
                      post.node.postThumbnail !== null
                        ? post.node.postThumbnail.file.url
                        : post.node.featuredImage[0].file.url
                    }
                    alt={
                      post.node.postThumbnail !== null
                        ? post.node.postThumbnail.description
                        : post.node.featuredImage[0].description
                    }
                  />
                  <CardTitle>{post.node.postTitle}</CardTitle>
                </Card>
              </Link>
            </CardContainer>
          );
        })}
      </Layout>
    );
  }
}

export default BlogView;
